export default Object.freeze({
    LAST_MODIFIED: "Last Modified",
    TOGGLE_WORK_FLOW_WARNING_TEXT: "Turning off the workflow at space type / state level would pause all the current delinquencies and will not initiate any new delinquencies for any tenant that goes delinquent. If this is turned back on then all the pause delinquencies would be resumed and the delinquent tenants with no delinquencies will be enrolled into the delinquency process once the next rent invoice is issued.",
    STAGE_UNSAVED_WARNING_DESC: "You haven\'t saved the action that you were working on. If you proceed then this unsaved action will not be added to this stage, to save this action please close this dialog box and click on the \'Add Action\' button on the left panel.",
    STAGE_PROPERTIES_WARNING_DESC: 'These changes will apply to [applied_properties_count] properties that are using this workflow. You can make specific changes to a property\'s workflow in the property settings which will unlink it from the corporate workflow.',
    STAGE_UNSAVED_WARNING_TITLE: 'Unsaved Changes',
    APPLY: "Apply",
    SAVE_STAGE: "Save Stage",
    RESET_DEFAULT: "Revert back to Tenant Process",
    RESET_CORPORATE: "Revert back to Corporate Default",
    TIMELINE_HEADING:"Hover over the automation stages to edit.",
    PROPERTY_LEVEL_PAUSE_TOOLTIP_TEXT: "Delinquency has been paused at the property level. To enable the button, resume delinquency at the property level.",
    NO_STAGE_CREATED: 'There are no stages created yet',
    DELINQUENCY_AUTOMATION: "Delinquency Automation",
    RESET_WARNING: "No automation reset possible",
    STATUSES: {
        TENANT_PROCESS: "tenant_process",
        CORPORATE_MODIFIED: "corporate_modified",
        PROPERTY_MODIFIED: "property_modified"
    },
    ICONS: {
        tenant_process: {
            name: "Tenant<sup>TM</sup> Process",
            slug: "tenant_process",
            icon: "mdi-custom-tenant-logo",
            icon_color: "#38AFC6"
        },
        corporate_modified: {
            name: "Corporate Modified",
            slug: "corporate_modified",
            icon: "mdi-check-circle",
            icon_color: "#02AD0F"
        },
        property_modified: {
            name: "Property Modified",
            slug: "property_modified",
            icon: "mdi-check-circle",
            icon_color: "#FFD600"
        }
    },
    MENU_TABS : {
        CORPORATE: "corporate",
        PROPERTY: "property",
    },
    APPLIED_PROPERTIES_TEXT: {
        CORPORATE: "Applies to",
        PROPERTY: "Shared Between",
        TOOLTIP_HEADER: {
            CORPORATE: "Applies to",
            PROPERTY: "Other properties using this same workflow"
        }
    },
    RESET_SUCCESS_MESSAGE: {
        CORPORATE_MODIFIED: "Automation Reset to Corporate Workflow Successfully",
        TENANT_PROCESS: "Automation Reset to Default Workflow Successfully"
    },
    TENANT_BECOMES_DELINQUENT: 'Tenant Becomes Delinquent',
    AFTER_RENT_IS_PAST_DUE: 'After Rent is Past Due',
    IMMEDIATELY: 'immediately',
    AFTER_ACTION: 'after_action',
    AFTER_PTOD: 'after_ptod',
    DAYS: 'days',
    AFTER_DELINQUENCY_START: 'after_delinquency_start',
    DAY_0_STAGE_TITLE: 'Rent is Due - Delinquency Start date',
    DAY_0_STAGE_DESCRIPTION: 'This is the day the tenant\'s rent is due',
    DAY_0_STAGE_TITLE_MARKETING: 'Send Confirmation Email and Text Message ',
    DAY_0_STAGE_DESCRIPTION_MARKETING: 'After completion, a confirmation email and text message will be sent.',
    DAY_0: 'Day 0',
    DAY_0_MARKETING:'Immediately',
    DELETE_DEPENDENCIES_TEXT: 'You can not delete stages or actions with dependent stages. If you would like to proceed with this deletion, you must address the dependencies first.',
    DELETE_STAGE_WARNING_TEXT: 'Are you sure you want to delete this stage or action? This action cannot be undone.',
    //marketing automation
    BEFORE_MOVE_IN_DATE:'Before Move-In Date',
    MARKETING_AUTOMATION:'Marketing Automation',
    HOURS_MIN:'hours-minutes',
    PENDING_MOVE_IN:'Pending Move In',
    RENTAL:'Rental',
    CART_ABANDONMENT:'Cart Abandonment',
    WEBSITE_RENTAL_FAILURE:'Website Rental Failure',
    NEW_LEAD:'New Lead (Inquiry)',
    RETIRE_LEAD:'Retire Lead',
    
    PAYMENT_REVERSAL_DESCRIPTION: 'This setting limits how far the automation will progress when aligning delinquency with the unpaid invoice date following a payment reversal.',
    PAYMENT_REVERSAL_TOOLTIP: 'This setting limits how far the automation will progress when aligning delinquency with the unpaid invoice date following a payment reversal.',
    ANCHOR_STAGE_TOOLTIP: 'Only a single stage can be set as the payment reversal stage and it cannot be a conditional stage.',
    AFTER_MOVE_IN_DATE:'After Move-In Date',
})
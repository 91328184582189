<template>
  <div>
    <v-divider></v-divider>
    <v-row v-if="!$props.facilityID" v-show="!isOPS" class="py-0">
      <v-col class="py-0 pr-0">
        <HbPropertySelector
            id="facility"
            :items="facilityList"
            :disabled="isLoading($options.name) || fetchingAccessControl"
            :loading="isLoading($options.name) || fetchingAccessControl"
            item-text="name"
            item-value="id"
            v-validate="'required'"
            v-model.lazy="property_id"
            data-vv-scope="gate"
            data-vv-name="facility"
            data-vv-as="Property"
            :error="errors.has('gate.facility')"
            @change="validateFacilitySelected()"
        />
      </v-col>
    </v-row>

    <v-row  class="my-0" >
      <v-col v-if="property_id">
        <access-control-configuration 
          v-if="isAccessControlConfigurationSelected"
          :syncReportFlag.sync="syncReportFlag" 
          :showAccessHoursSettings="showAccessHoursSettings" 
          :property_id="property_id"
          :fetchingAccessControl.sync="fetchingAccessControl"
        />
        <sync-reports v-else :property_id="property_id"/>
      </v-col>
      <v-col v-else>
        <hb-empty-state
            message="Please select a Property to continue."
        >
        </hb-empty-state>
      </v-col>
      <v-col style="max-width: 250px;">
        <div  >
          <v-container class="ma-0 pa-0" style="height: 75vh;height: 75svh;" fluid>
            <v-row class="ma-0 pa-0">
              <v-col :cols="showTertiary ? 0 : 0" class="ma-0 pa-0" style="height: 75vh;height: 75svh;" v-if="showTertiary">
                <hb-page-menu :width="'250px'" v-model="showTertiary">
                  <v-list dense>
                    <v-list-item-group v-model="selection" mandatory>
                      <v-list-item v-for="item in menuItems" exact :key="item">
                        {{item}}
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </hb-page-menu>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>


  </div>
</template>

<script type="text/babel">
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import AccessControlConfiguration from "./AccessControlConfiguration.vue";
import {mapGetters, mapActions} from 'vuex';
import { EventBus } from '../../../EventBus.js';
import SyncReports from "./SyncReports.vue";

export default {
  name: "AccessControl",
  components: {SyncReports, AccessControlConfiguration},
  mixins: [notificationMixin],
  props: ["facilityID","isOPS", "showAccessHoursSettings"],
  data() {
    return {
      selection: 0,
      property_id: "",
      showTertiary: true,
      syncReportFlag: false,
      fetchingAccessControl: false
    };
  },
  computed: {
    ...mapGetters({
      onboardingData: "onboardingTabsStore/getOnboardingData",
      facilityList: 'propertiesStore/filtered',
      defaultProperty: "propertiesStore/defaultProperty"
      
    }),
    menuItems() {
      let menuList = ['Access Control Configuration']
      if (this.syncReportFlag === true) menuList.push('Sync Reports')
      return menuList
    },
    isAccessControlConfigurationSelected() {
      return this.selection === 0;
    }
  },
  methods: {
    ...mapActions({
      setDefaultProperty: 'propertiesStore/setDefaultProperty',
    }),
    async validateFacilitySelected() {
      let gate_status = await this.$validator.validate("gate.facility");
      if (!gate_status) {
        var collectables = this.errors.items.filter(obj => obj.rule === "unique_external_id");
        this.showMessageNotification({
          type: 'error',
          description: 'There are errors in your form, correct them before continuing.',
          list: this.errors.items[0]
        });
        return;
      }
    }
  },
  watch: {
    async property_id(propertyValue) {
      EventBus.$emit('selectedAccessProperty', propertyValue);
    },
  },
  mounted() {
    if (this.$props.facilityID) {
      this.property_id = this.$props.facilityID;
    }
    if(this.isOPS){
      this.property_id = this.onboardingData.property.id;
    }
    if (this.property_id) EventBus.$emit('selectedAccessProperty', this.property_id);
  }

};
</script>

<style scoped>

</style>
